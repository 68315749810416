/* You can add global styles to this file, and also import other style files */
body {
  position: inherit;
}

#NavSection {
  background: #fff;

  p {
    font-size: 12px;
    margin-left: -15px;
  }

  a {
    cursor: pointer;
  }
}

#HeaderSection {
  se-persona-avatar {
    float: left;

    .se-avatar--300--with-image {
      border: 2px solid #fff;
    }
  }

  h2,
  p {
    margin-left: 60px;
  }

  h2 {
    font-size: 12px;
    margin-top: 5px;
  }

  p {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

#FooterSection {
  padding-top: 15px;

  #LegalFooter {
    margin-top: 50px;
    margin-bottom: 10px;
  }
}

#ProgressSection {
  margin: 0;
  padding: 0;
}

select {
  width: 84.5px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #d3d4d5;
  background-image: linear-gradient(to bottom, #ffffff, #fafbfb);
  padding-right: 4px;
}

input {
  width: 362px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(35, 40, 43, 0.2);
  background-color: #ffffff;
}

input[type="submit"],
input[type="button"] {
    width: 100%;
    height: 40px;
    border-radius: 4px;
}

a {
  display:inline-block;
  * {
  pointer-events: none;
  }
}

